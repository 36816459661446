import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { EditIcon, DeleteIcon } from "api/icons/GlobalIcon";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ImagePlaceHolder from "components/ImagePlaceHolder/ImagePlaceHolder";
import urlUtils from "../../utils/url";
import styles from "./CardRow-jss";

const CardRow = ({ img, name, handelDelete, path, state, classes }) => (
  <Card className={classes.card}>
    <CardHeader
      className={classes.cardHeader}
      title={name || ""}
      avatar={(
        <figure className={classes.brandLogo}>
          <ImagePlaceHolder imageSrc={urlUtils.getProperImageUrl(img)} name={name} numOfLetters={2} alt={name} className={classes.brandImage} />
        </figure>
      )}
      action={(
        <div className={classes.cardActions}>
          <Link
            to={{
              pathname: path,
              state: { state },
            }}
          >
            <EditIcon className={classes.editIcon} />
          </Link>
          <button type="button" onClick={handelDelete}>
            <DeleteIcon />
          </button>
        </div>
      )}
    />
  </Card>
);

export default withStyles(styles)(CardRow);
