import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { PropTypes } from "prop-types";

/**
 * ImagePlaceHolder Component
 *
 * A versatile component for rendering images or avatars with fallback options.
 *
 * @component
 * @example
 * // Rendering an image
 * <ImagePlaceHolder imageSrc="/path/to/image.jpg" alt="Image Alt Text" className="custom-class" />
 *
 * // Rendering a placeholder image
 * <ImagePlaceHolder placeHolderImg="/path/to/placeholder.jpg" alt="Placeholder Alt Text" className="custom-class" />
 *
 * // Rendering an avatar with initials
 * <ImagePlaceHolder name="John Doe" className="custom-class" numOfLetters={2} />
 *
 * @param {Object} props - The properties of the ImagePlaceHolder component.
 * @param {string} [props.imageSrc] - The source URL of the image to be displayed.
 * @param {string} [props.placeHolderImg] - The source URL of a placeholder image to be displayed if imageSrc is not provided.
 * @param {string} [props.alt="image"] - The alternative text for the image or placeholder image.
 * @param {string} [props.className=""] - The custom CSS class for styling the component.
 * @param {string} [props.name] - The name to be used for generating initials in case neither imageSrc nor placeHolderImg is provided.
 * @param {number} [props.numOfLetters=1] - The number of initials to be displayed when rendering an avatar.
 *
 * @returns {JSX.Element} - The rendered ImagePlaceHolder component.
 */

const ImagePlaceHolder = ({
  imageSrc,
  placeHolderImg,
  alt,
  className,
  name,
  numOfLetters,
}) => {
  if (imageSrc) {
    return <img src={imageSrc} alt={alt} className={className} />;
  }

  if (!imageSrc && placeHolderImg) {
    return <img src={placeHolderImg} alt={alt} className={className} />;
  }

  if (!imageSrc && !placeHolderImg && name) {
    return (
      <Avatar className={className} aria-label="avatar">
        {Array.from(Array(numOfLetters).keys())?.map((index) => name[index])}
      </Avatar>
    );
  }

  return <></>;
};

ImagePlaceHolder.PropTypes = {
  imageSrc: PropTypes.string,
  placeHolderImg: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  numOfLetters: PropTypes.number,
};

ImagePlaceHolder.defaultProps = {
  alt: "image",
  className: "",
  numOfLetters: 1,
};

export default ImagePlaceHolder;
