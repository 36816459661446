const currencies = Object.freeze([
  {
    symbol: "EGP",
    iso: 818,
    id: 1,
  },
  {
    symbol: "NGN",
    iso: 566,
    id: 10
  },
  // {
  //   symbol: "USD",
  //   iso: 840,
  //   id: 2,
  // }
]);

const unfrozenCurrencyByIso = {};
const unfrozenCurrencyBySymbol = {};
const unfrozenCurrencyById = {};

currencies.forEach(currency => {
  unfrozenCurrencyByIso[currency.iso] = currency;
  unfrozenCurrencyBySymbol[currency.symbol] = currency;
  unfrozenCurrencyById[currency.id] = currency;
});

const currencyByIso = Object.freeze(unfrozenCurrencyByIso);
const currencyBySymbol = Object.freeze(unfrozenCurrencyBySymbol);
const currencyById = Object.freeze(unfrozenCurrencyById);

const priceFormatting = (currency) =>
  new Intl.NumberFormat("en-US", {
    ...(currency
      ? {
        style: "currency",
        currency,
      } : {}),
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const getCurrencyFormatter = (currency) => (value) =>
  (Number.isFinite(Number(value))
    ? priceFormatting(currency)
      .format(Math.abs(value))
      .replace("\xa0", "\xa0" + (value < 0 ? "-" : ""))
    : "");

export {
  currencies,
  currencyByIso,
  currencyBySymbol,
  currencyById,
  getCurrencyFormatter,
};
