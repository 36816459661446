import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { BarChart, Bar, AreaChart, Area, LineChart, Line } from "recharts";
import { data1 } from "api/chart/chartMiniData";
import colorfull from "api/palette/colorfull";
import AssignmentReturned from "@material-ui/icons/AssignmentReturned";
import { RequestGroup } from "utils/data";
import { connect } from "react-redux";
import CounterWidget from "../Counter/CounterWidget";
import styles from "./widget-jss";

class CounterChartWidget extends PureComponent {
  requestGroup = new RequestGroup();

  constructor(props) {
    super(props);
    this.state = {
      activeUsers: 0,
      newUsers: 0,
      monthlyIncome: 0,
      weeklySales: 0,
    };
  }

  componentDidMount() {
    this.getStats();
  }

  getStats = async () => {
    const date = new Date();
    const month = date.getUTCMonth() + 1; // not sure about utc
    const week = Math.ceil(date.getUTCDate() / 7);
    const {
      monthly_income: monthlyIncome,
      weekly_sales: weeklySales,
    } = await this.requestGroup.getStatisticsSales(month, week);
    this.setState({ monthlyIncome, weeklySales });
    const {
      active_users: activeUsers,
      new_users: newUsers,
    } = await this.requestGroup.getStatisticsUsers(month);
    this.setState({ activeUsers, newUsers });
  };

  render() {
    const { classes, currency } = this.props;
    const { activeUsers, newUsers, monthlyIncome, weeklySales } = this.state;
    return (
      <div className={classes.rootCounter}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={6}>
            <CounterWidget
              color={colorfull[6]}
              start={0}
              end={monthlyIncome / 1000}
              duration={3}
              title="Monthly Income"
              unitBefore={currency + " "}
              unitAfter="k"
            >
              <AssignmentReturned className={classes.counterIcon} />
            </CounterWidget>
          </Grid>
          <Grid item md={3} xs={6}>
            <CounterWidget
              color={colorfull[3]}
              start={0}
              end={weeklySales}
              duration={3}
              title="Weekly Sales"
            >
              <BarChart width={100} height={40} data={data1}>
                <Bar dataKey="uv" fill="#ffffff" />
              </BarChart>
            </CounterWidget>
          </Grid>
          <Grid item md={3} xs={6}>
            <CounterWidget
              color={colorfull[5]}
              start={0}
              end={newUsers}
              duration={3}
              title="New Customers"
            >
              <AreaChart width={100} height={60} data={data1}>
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="#FFFFFF"
                  fill="rgba(255,255,255,.5)"
                />
              </AreaChart>
            </CounterWidget>
          </Grid>
          <Grid item md={3} xs={6}>
            <CounterWidget
              color={colorfull[4]}
              start={0}
              end={activeUsers}
              duration={3}
              title="Active Users"
            >
              <LineChart width={100} height={80} data={data1}>
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#FFFFFF"
                  strokeWidth={2}
                />
              </LineChart>
            </CounterWidget>
          </Grid>
        </Grid>
      </div>
    );
  }
}

CounterChartWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
};

const ReduxedCounterChartWidget = connect((state) => ({
  currency: state.getIn([
    "cachedData",
    "cache",
    "orgData",
    "value",
    "currency",
  ]),
}))(CounterChartWidget);

export default withStyles(styles)(ReduxedCounterChartWidget);
