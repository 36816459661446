import { fromJS, Map } from "immutable";
import {
  SUBMIT_STARTED,
  SUBMIT_PROGRESS,
  SUBMIT_COMPLETED,
  SUBMIT_ERROR
} from "../../actions/actionConstants";

const initialState = {};

const initialImmutableState = fromJS(initialState);
export default function reducer(state = initialImmutableState, action = {}) {
  switch (action.type) {
    case SUBMIT_STARTED:
      return state.withMutations(mutableState => {
        mutableState.setIn([action.formName, "cancel"], action.cancel); // cancel may need to be immutable
        mutableState.setIn([action.formName, "progress"], 0);
      });
    case SUBMIT_PROGRESS:
      return state.setIn([action.formName, "progress"], action.progress);
    case SUBMIT_ERROR:
    case SUBMIT_COMPLETED:
      return state.remove(action.formName);
    default:
      return state;
  }
}
