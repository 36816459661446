import pink from "@material-ui/core/colors/pink";
import purple from "@material-ui/core/colors/purple";
import orange from "@material-ui/core/colors/orange";
import teal from "@material-ui/core/colors/teal";
import lightGreen from "@material-ui/core/colors/lightGreen";
import lightBlue from "@material-ui/core/colors/lightBlue";
import indigo from "@material-ui/core/colors/indigo";
const colorfull = [
  pink[400],
  purple[500],
  lightBlue[500],
  teal[500],
  orange[600],
  lightGreen[600],
  indigo.A200
];
export default colorfull;
