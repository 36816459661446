import React, { Component, createRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { PapperBlock } from "components";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { canSelectShop } from "utils/users";
import { connect } from "react-redux";
import ActionsButton from "components/ActionsButton/ActionsButton";
import { RequestGroup } from "../../../utils/data";

const styles = {
  root: {
    flexGrow: 1,
  },
  fieldsWrapper: {
    overflow: "auto",
    padding: "30px 30px 0",
  },
  field: {
    width: "100%",
    marginBottom: 20,
  },
};

class ExportProducts extends Component {
  requestGroup = new RequestGroup();

  downloadAnchor = createRef();

  constructor(props) {
    super(props);
    this.state = {
      shops: [],
      shopId: "",
      sheetUrl: undefined,
      sheetType: undefined,
    };
    this.getShops();
  }

  componentWillUnmount() {
    this.requestGroup.cancelAndReuse();
  }

  getShops = () => {
    const { userRoles, userShopId } = this.props;
    const userCanSelectShops = canSelectShop(userRoles);
    if (userCanSelectShops) {
      this.requestGroup.getShops().then((shops) => {
        this.setState({
          shops: shops.content || [],
        });
      });
    } else if (userShopId) {
      this.requestGroup.getShop(userShopId).then((shop) => {
        this.setState({
          shops: [shop],
          shopId: userShopId,
        });
      });
    }
  };

  handleShopChange = (event) => {
    this.setState({ shopId: event.target.value });
  };

  sheetFetched = (sheetType) => (data) => {
    const sheetUrl = URL.createObjectURL(data);
    this.setState(
      {
        sheetUrl,
        sheetType,
      },
      () => this.downloadAnchor.current.click()
    );
  };

  getProductsCsv = () => {
    const { shopId } = this.state;
    this.requestGroup.getExportProductsCsv(shopId).then(this.sheetFetched("csv"));
  };

  getProductsExcel = () => {
    const { shopId } = this.state;
    this.requestGroup.getExportProductsExcel(shopId).then(this.sheetFetched("xlsx"));
  };

  render() {
    const { classes, userRoles } = this.props;
    const { shops, shopId, sheetUrl, sheetType } = this.state;
    const userCanSelectShops = canSelectShop(userRoles);
    return (
      <PapperBlock title="Export Products">
        <Grid
          container
          spacing={3}
          alignItems="flex-start"
          direction="row"
          justify="center"
        >
          <Grid item xs={12} md={6}>
            <Paper className={classes.root}>
              <div className={classes.fieldsContainer}>
                <div className={classes.fieldsWrapper}>
                  <FormControl key="shops" required className={classes.field} disabled={!userCanSelectShops}>
                    <InputLabel>Shop</InputLabel>
                    <Select value={shopId} onChange={this.handleShopChange}>
                      {shops.map(({ id, name: shopName }) => (
                        <MenuItem key={id} value={id}>
                          {shopName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <ActionsButton
                    disabled={!shopId}
                    groupText="Export Products"
                    options={[
                      {
                        text: "export as csv",
                        onClick: this.getProductsCsv,
                      },
                      {
                        text: "export as excel",
                        onClick: this.getProductsExcel,
                      },
                    ]}
                    className={classes.field}
                  />
                  <a
                    style={{ display: "none" }}
                    ref={this.downloadAnchor}
                    href={sheetUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={`products.${sheetType}`}
                    disabled={!sheetUrl || !sheetType}
                  >
                    download
                  </a>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </PapperBlock>
    );
  }
}

ExportProducts.propTypes = {
  classes: PropTypes.object.isRequired,
  userRoles: PropTypes.array.isRequired,
  userShopId: PropTypes.number,
};

ExportProducts.defaultProps = {
  userShopId: undefined,
};

const ConnectedExportPRoducts = connect((state) => ({
  userRoles: state.getIn(["users", "user", "info", "roles"]),
  userShopId: state.getIn(["users", "user", "info", "shop_id"]),
}))(ExportProducts);

export default withStyles(styles)(ConnectedExportPRoducts);
