module.exports = {
  dashboard: "/",
  login: "/login",
  market: "#",
  email: "#",
  profile: "#",
  calendar: "#",
  twitter: "#",
  github: "#",
  pinterest: "#",
  linkedin: "#"
};
