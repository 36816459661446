import { darken, fade } from "@material-ui/core/styles/colorManipulator";

const drawerWidth = 245;
const drawerBigWidth = 245;

const styles = (theme) => ({
  toolBarContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingInline: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      paddingInline: theme.spacing(3),
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("xs")]: {
      paddingInline: theme.spacing(1),
      justifyContent: "space-between",
    },
  },
  appBar: {
    backgroundColor: "transparent",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin", "background"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  attachedbar: {
    position: "relative",
    "& $wrapper": {
      [theme.breakpoints.down("lg")]: {
        border: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  floatingBar: {
    position: "static",
    "&$left": {
      paddingRight: "2rem",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "1rem",
        paddingLeft: "1rem",
      },
    },
    "&$right": {
      paddingLeft: "2rem",
    },
  },
  appMenu: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    [theme.breakpoints.down("md")]: {
      padding: `${theme.spacing(0.5)}px 0`,
    },
    [theme.breakpoints.up("lg")]: {
      background: fade(theme.palette.background.paper, 0.8),
    },
    color: theme.palette.text.primary,
  },
  flex: {
    flex: 1,
    textAlign: "right",
  },
  flexDefault: {
    flex: 1,
    textAlign: "right",
  },
  appBarShift: {
    transition: theme.transitions.create(["width", "margin", "background"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "&$left": {
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        borderBottom: "1.5px solid rgba(255, 255, 255, 0.30)",
        background: "rgba(255, 255, 255, 0.40)",
        "-webkit-backdrop-filter": "blur(50px)",
        backdropFilter: "blur(50px)",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        paddingRight: "1rem",
      },
    },
    "&$leftBig": {
      [theme.breakpoints.up("lg")]: {
        marginLeft: drawerBigWidth,
        width: `calc(100% - ${drawerBigWidth}px)`,
      },
    },
    "&$right": {
      paddingTop: ".1rem",
      paddingLeft: "2rem",
      paddingBottom: "1rem",
      [theme.breakpoints.up("lg")]: {
        marginRight: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
  },
  menuButton: {
    width: 24,
    height: 24,
    minHeight: 24,
    padding: 4,
    border: "1px solid #FFF",
    marginLeft: 32,
    background: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  menuOpenButton: {
    marginLeft: "-2px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  hide: {
    display: "none",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  wrapper: {
    fontFamily: theme.typography.fontFamily,
    position: "relative",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    borderRadius: 22,
    display: "inline-block",
    "&:hover": {
      background: fade(theme.palette.common.white, 0.25),
    },
    "&$light": {
      background:
        theme.palette.type === "dark"
          ? fade(theme.palette.common.white, 0.2)
          : fade(theme.palette.common.black, 0.4),
    },
    "&$dark": {
      background:
        theme.palette.type === "dark"
          ? theme.palette.grey[700]
          : fade(theme.palette.common.white, 0.8),
      boxShadow: theme.shade.light,
      "& input": {
        color: theme.palette.grey[700],
      },
      "& input::placeholder": {
        color: theme.palette.grey[400],
        opacity: 1 /* Firefox */,
      },
      "& input:-ms-input-placeholder": {
        color: theme.palette.grey[400],
      },
      "& input::-ms-input-placeholder": {
        /* Internet Explorer 10-11 */ color: theme.palette.grey[400],
      },
    },
    "& $miniInput": {
      width: 70,
    },
  },
  userAvatarBar: {
    width: 45,
    height: 45,
    minWidth: "auto",
    borderRadius: "50%",
    padding: 0,
    "& span": {
      height: "100%",
      "& div": {
        width: "100%",
        height: "100%",
      },
    },
  },
  infoContainer: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  welcomeUser: {
    textAlign: "center",
    textTransform: "capitalize",
    color: "#8B909A",
    fontSize: 16,
    "& :nth-child(1)": {
      fontWeight: 500,
      marginRight: 4,
    },
    "& :nth-child(2)": {
      fontWeight: 600,
      color: "#636467",
    },
    "& :nth-child(3)": {
      fontWeight: 500,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  userDropdownMenu: {
    position: "relative",
    marginTop: "60px !important",
    "& ul": {
      padding: 2,
    },
  },
  searchWrapperContainer: {
    background:
      theme.palette.type === "dark"
        ? `${theme.palette.borderDark} !important`
        : "#fff !important",
    "& svg": {
      color:
        theme.palette.type === "dark" ? "#fff" : theme.palette.primary.main,
    },
  },
  searchWrapper: {
    [theme.breakpoints.down("md")]: {
      textAlign: "right",
    },
    "&:hover": {
      background: "unset",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  search: {
    width: theme.spacing(9),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& svg": {
      fontSize: "27px",
      color: "#0000008A",
    },
  },
  miniInput: {
    paddingLeft: 0,
    textIndent: "999999px",
  },
  gradientBg: {},
  solidBg: {},
  darker: {
    backgroundAttachment: "fixed",
    boxShadow: theme.shadows[3],

    "&$gradientBg": {
      borderBottom: "1.5px solid rgba(255, 255, 255, 0.30)",
      background: "rgba(255, 255, 255, 0.40)",
      "-webkit-backdrop-filter": "blur(50px)",
      backdropFilter: "blur(50px)",
    },
    "&$solidBg": {
      backgroundColor:
        theme.palette.type === "dark"
          ? darken(theme.palette.primary.main, 0.4)
          : theme.palette.primary.main,
    },
  },
  fixed: {
    position: "fixed",
    left: 0,
    top: 0,
    [theme.breakpoints.up("lg")]: {
      top: theme.spacing(1) * -8,
    },
    "& nav": {
      padding: "16px 0",
    },
  },

  notifMenu: {
    marginTop: "40px !important",
    "& ::-webkit-scrollbar": { width: "4px !important" },
    "& li": {
      height: "auto",
      "& h3": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    },
  },
  badgeMenu: {
    "& span": {
      top: 0,
      right: -30,
    },
  },
  badgeColor: {
    "& span": {
      backgroundColor: "#f00",
      color: "#fff",
      fontSize: "12px",
    },
  },
  textNotif: {
    "& span": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "block",
    },
  },
  notifIcon: {
    width: 45,
    height: 45,
    background: "rgba(255, 255, 255, 0.40)",
    border: "1px solid rgba(208, 207, 218, 0.40)",
    transition: "all 0.3s ease-in-out",
    "& svg path": {
      width: 20,
      height: 20,
      fill:
        theme.palette.type === "dark"
          ? fade(theme.palette.common.white, 0.87)
          : theme.palette.iconColor,
    },
  },
  iconColor: {
    background: "rgba(255, 255, 255, 0.20)",
    border: "1px solid rgba(220, 219, 226, 0.40)",
    "& svg path": {
      fill: "white",
    },
  },
  notificationLink: {
    textDecoration: "none"
  },
  divider: {
    margin: "5px 0",
  },
  chatLink: {
    textDecoration: "none",
    color: "#000",
  },
  unReadMessage: {
    background: "#f6f6f6",
  },
  chatItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& h5": {
      margin: 0,
      fontSize: ".9rem",
      fontFamily: "Open Sans,sans-serif",
      fontWeight: "400",
      marginBottom: "5px",
    },
    "& p": {
      display: "flex",
      alignItems: "center",
      gap: "5px",
      margin: 0,
      fontSize: ".7 5rem",
      fontFamily: "Open Sans,sans-serif",
      fontWeight: "400",
      "& span": {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
  },
  dot: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    background: "rgba(0, 0, 0, 0.54)",
  },
  chatInfo: {
    marginLeft: "10px",
  },
  brand: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 20px 5px",
    height: 64,
    position: "absolute",
    width: "100%",
    fontSize: 16,
    margin: 0,
    fontWeight: 500,
    textDecoration: "none",
    color: theme.palette.text.primary,
    "& img": {
      marginRight: 10,
      width: 104,
    },
  },
  mainMenu: {
    backgroundColor: theme.palette.background.paper,
    padding: `${theme.spacing(1)}px 0`,
    boxShadow: theme.shadows[3],
    position: "relative",
    transition: "padding 0.3s ease",
    "& > div": {
      display: "flex",
      justifyContent: "center",
    },
  },
  headMenu: {
    fontSize: 12,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(
      0.5
    )}px ${theme.spacing(2)}px`,
    minHeight: "auto",
    margin: `0 ${theme.spacing(0.5)}px`,
  },
  opened: {
    color: theme.palette.primary.main,
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
    "& svg": {
      fill: theme.palette.primary.main,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(0.5),
    opacity: 0.3,
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.light,
    "&:hover": {
      background: theme.palette.primary.main,
    },
    "& svg": {
      fill: theme.palette.primary.light,
    },
    "& $rightIcon": {
      opacity: 0.7,
    },
  },
  paperMenu: {
    overflow: "auto",
    maxHeight: 500,
  },
  popperClose: {
    pointerEvents: "none",
    zIndex: 2,
  },
  title: {
    fontSize: 10,
    textTransform: "uppercase",
    display: "block",
    color: theme.palette.secondary.main,
    lineHeight: "28px",
    fontWeight: "bold",
    background: theme.palette.background.paper,
    borderRadius: theme.rounded.medium,
  },
  dropDownMenu: {
    minWidth: 300,
    marginTop: theme.spacing(1.5),
    position: "relative",
  },
  active: {},
  menuItem: {
    "& span": {
      fontSize: 14,
    },
    "&$active": {
      borderLeft: `5px solid ${theme.palette.primary.main}`,
      backgroundColor:
        theme.palette.type === "dark"
          ? fade(theme.palette.secondary.main, 0.24)
          : theme.palette.secondary.light,
      "& span": {
        color: theme.palette.primary.main,
      },
      "&:hover": {
        backgroundColor:
          theme.palette.type === "dark"
            ? fade(theme.palette.secondary.main, 0.24)
            : theme.palette.secondary.light,
      },
    },
  },
  megaMenu: {
    padding: theme.spacing(2),
    "& $title": {
      paddingLeft: theme.spacing(2),
    },
  },
  megaItem: {
    display: "inline-block",
    width: "auto",
    margin: theme.spacing(1),
    borderRadius: theme.rounded.big,
    padding: `${theme.spacing(0.25)}px ${theme.spacing(1)}px`,
    "& span": {
      fontSize: 14,
    },
    "& div": {
      padding: 0,
    },
    "&$active": {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor:
        theme.palette.type === "dark"
          ? fade(theme.palette.secondary.main, 0.24)
          : theme.palette.secondary.light,
      "& span": {
        color: theme.palette.primary.main,
      },
      "&:hover": {
        backgroundColor:
          theme.palette.type === "dark"
            ? fade(theme.palette.secondary.main, 0.24)
            : theme.palette.secondary.light,
      },
    },
  },
  bigIcon: {
    display: "block",
    marginTop: 40,
    "& svg": {
      width: 100,
      height: 100,
      fill: theme.palette.primary.main,
      margin: "0 auto",
      display: "inherit",
    },
  },
  headerProperties: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flex: 1,
    alignItems: "center",
    zIndex: 1,
  },
  fadeOut: {},
  invert: {},
  headerAction: {
    display: "flex",
    margin: `0 ${theme.spacing(3)}px`,
    [theme.breakpoints.down("xs")]: {
      margin: `0 ${theme.spacing(1)}px`,
      "& .MuiIconButton-root": {
        padding: "4px !important",
      },
    },
    gap: "8px",
    transition: "opacity 0.5s ease",
    "& $button": {
      margin: `0 ${theme.spacing(1)}px / 2`,
      "& svg": {
        fill:
          theme.palette.type === "dark"
            ? fade(theme.palette.common.white, 0.87)
            : theme.palette.primary.mainLight,
        width: 28,
        height: 28,
      },
    },
    "&$fadeOut": {
      opacity: 1,
      "& button": {
        "& span": {
          "& svg": {
            "& path": {
              fill: theme.palette.primary.mainLight,
            },
          },
        },
      },
    },
    "&$invert": {
      "& $button": {
        "& svg": {
          fill: fade(theme.palette.text.primary, 0.5),
        },
      },
    },
  },
  show: {},
  headerTitle: {
    fontSize: 28,
    textTransform: "capitalize",
    fontWeight: 600,
    color: "black",
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  headerTitleWhite: {
    color: "white",
  },
  headerTitleBlack: {
    color: "black",
  },
  swipeDrawerPaper: {
    width: drawerWidth,
  },
  searchHeaderMenu: {
    flex: 1,
    flexDirection: "row-reverse",
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    background: "white",
    border: "1.125px solid #0000001F",
    color: theme.palette.iconColor,
    fontSize: "20px",
  },
  infoIcon: {
    "& path": {
      fill: theme.palette.iconColor,
    },
  },
  logOutContainer: {
    minWidth: "150px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  iconContainer: {
    position: "relative",
    "& svg": {
      width: 20,
      height: 20,
    },
  },
  unRead: {
    "&:after": {
      content: "''",
      position: "absolute",
      top: -2,
      right: 0,
      width: 10,
      height: 10,
      background: "#e4def7",
      zIndex: 1,
      borderRadius: "50%"
    },
  },
  burgerIcon: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  }
});

export default styles;
