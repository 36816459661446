import { Map, OrderedMap } from "immutable";
import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION
} from "../../actions/actionConstants";

const initialState = {
  nextKey: 1,
  notifications: OrderedMap()
};

const initialImmutableState = Map(initialState);
export default function reducer(state = initialImmutableState, action = {}) {
  const nextKey = state.get("nextKey");
  switch (action.type) {
    case ADD_NOTIFICATION:
      return state
        .setIn(["notifications", nextKey], action.message)
        .set("nextKey", nextKey + 1);
    case REMOVE_NOTIFICATION:
      return state.deleteIn(["notifications", action.key]);
    default:
      return state;
  }
}
