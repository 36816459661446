import React from "react";

const SmartPhoneIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0989 2.06042H6.01278C4.03191 2.06042 3.31421 2.77813 3.31421 4.79488V13.6801C3.31421 15.6968 4.03191 16.4145 6.01278 16.4145H12.0917C14.0798 16.4145 14.7975 15.6968 14.7975 13.6801V4.79488C14.7975 2.77813 14.0798 2.06042 12.0989 2.06042ZM9.05585 14.4767C8.36686 14.4767 7.79987 13.9097 7.79987 13.2207C7.79987 12.5317 8.36686 11.9648 9.05585 11.9648C9.74485 11.9648 10.3118 12.5317 10.3118 13.2207C10.3118 13.9097 9.74485 14.4767 9.05585 14.4767ZM10.4913 5.11067H7.62044C7.32618 5.11067 7.08216 4.86665 7.08216 4.57239C7.08216 4.27814 7.32618 4.03412 7.62044 4.03412H10.4913C10.7855 4.03412 11.0295 4.27814 11.0295 4.57239C11.0295 4.86665 10.7855 5.11067 10.4913 5.11067Z"
      fill="#FF8042"
    />
  </svg>
);

export default SmartPhoneIcon;
