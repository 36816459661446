import { useDrag, useDrop } from "react-dnd";

const DragAndDrop = ({
  children,
  id,
  index,
  type,
  onDrop = () => {},
  onHover = () => {},
  onEnd = () => {},
}) => {
  const [{ isDragging }, dragRef, previewRef] = useDrag({
    item: { type, id, index },
    endDrag: (...params) => { // not called! not sure if library bug
      onEnd(...params);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ isOver, itemWasBelow }, dropRef] = useDrop({
    accept: type,
    drop: (item, monitor) => {
      if (item.id !== id) {
        onDrop(item, monitor);
      }
    },
    hover: (item, monitor) => {
      if (item.id !== id) {
        onHover(item, monitor);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver() && monitor.getItem().id !== id,
      itemWasBelow: monitor.getItem()?.index > index,
    }),
  });
  return children({
    dragRef,
    previewRef,
    isDragging,
    dropRef,
    isOver,
    itemWasBelow,
  });
};

export default DragAndDrop;
