import React, { useState } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./CustomAccordion-jss";
const CustomAccordion = ({
  bordered,
  primary,
  label,
  children,
  className,
  classes,
  opened,
}) => {
  const [open, setOpen] = useState(opened || false);
  return (
    <div className={classNames(className, classes.accordion)}>
      <button
        onClick={() => setOpen((prev) => !prev)}
        className={classNames(
          classes.accordionTitle,
          bordered ? classes.bordered : "",
          primary ? classes.primary : ""
        )}
      >
        {label}
        <span
          style={{
            transform: open ? "rotate(180deg)" : "",
          }}
        >
          <ExpandMoreIcon />
        </span>
      </button>

      <div
        className={classNames(classes.content, open ? classes.open : "open")}
      >
        {children}
      </div>
    </div>
  );
};

export default withStyles(styles)(CustomAccordion);
