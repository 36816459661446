import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import ActionDelete from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import { EditIcon, DeleteIcon } from "api/icons/GlobalIcon";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import urlUtils from "../../utils/url";
import styles from "./tagCard-jss";

const TagCard = ({ tag, deleteTag, classes }) => {
  const {
    id,
    p_name: pName,
    alias,
    metadata: { icon, banner },
  } = tag;
  return (
    <Card className={classes.card}>
      <CardHeader
        classes={classes.cardHeader}
        title={<h4 className={classes.cardTitle}>{alias}</h4>}
        // avatar={(
        //   <img
        //     src={urlUtils.getProperImageUrl(icon)}
        //     alt=""
        //     className={classes.logo}
        //   />
        // )}
        action={(
          <div className={classes.cardActions}>
            <Link
              to={{
                pathname: `/tags/${id}/${pName}`,
                state: { tag },
              }}
            >
              <EditIcon />
            </Link>
            <button type="button" onClick={() => deleteTag(id, alias)}>
              <DeleteIcon />
            </button>
          </div>
        )}
      />
      <figure className={classes.media}>
        <img src={urlUtils.getProperImageUrl(banner)} alt={alias} />
      </figure>
      {/* <CardMedia
        className={classes.media}
        image={urlUtils.getProperImageUrl(banner)}
      /> */}
    </Card>
  );
};

const StyledTagCard = withStyles(styles)(TagCard);

export default StyledTagCard;
