const styles = (theme) => ({
  dashboardWrapper: {
    marginInline: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginInline: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginInline: theme.spacing(1),
    },
  },
  marginAll: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
});

export default styles;
