import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { EmptyData } from "components";
import Spinner from "components/Spinner/Spinner";
import EnhancedDataTable from "components/Tables/EnhancedDataTable";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import OrdersIcon from "api/icons/DashboardIcons/OrdersIcon";
import DotsVertical from "api/icons/StatisticsIcons/DotsVertical";
import ArrowDown from "api/icons/ArrowDown";
import messageStyles from "styles/components/Messages.module.scss";
import { RequestGroup } from "../../utils/data";
import PapperBlock from "../PapperBlock/PapperBlock";
import styles from "./widget-jss";

class LatestOrders extends React.Component {
  requestGroup = new RequestGroup();

  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      loadingOrders: false,
      page: 0,
      rowsPerPage: 3,
      total: 0,
    };
  }

  componentDidMount() {
    this.getOrders();
  }

  componentWillUnmount() {
    this.requestGroup.cancelAndReuse();
  }

  getOrders = () => {
    this.setState({ loadingOrders: true, currentOrder: null });
    const { page, rowsPerPage } = this.state;
    this.setState({ loadingOrders: true });
    const start = page * rowsPerPage;
    const count = rowsPerPage;
    const created_after = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().replace("T", ":").split(".")[0];
    const created_before = new Date().toISOString().replace("T", ":").split(".")[0];
    this.requestGroup
      .getOrderList({ details_level: 2, start, count, created_after, created_before })
      .then(({ total, orders }) => {
        this.setState({ orders, loadingOrders: false, total });
      })
      .catch(() => {
        this.setState({ loadingOrders: false });
      });
  };

  getStatus = (status) => {
    switch (status) {
      case "CLIENT_CONFIRMED":
        return messageStyles.bgDefault;
      case "STORE_CONFIRMED":
        return messageStyles.bgInfo;
      case "STORE_PREPARED":
        return messageStyles.bgWarning;
      case "DISPATCHED":
        return messageStyles.bgSuccess;
      default:
        return messageStyles.bgError;
    }
  };

  getPaymentStatus = (status) => {
    switch (status) {
      case "UNPAID":
        return messageStyles.bgError;
      case "PAID":
        return messageStyles.bgSuccess;
      default:
        return messageStyles.bgDefault;
    }
  };

  render() {
    const { classes } = this.props;
    const { orders, loadingOrders, page, rowsPerPage, total } = this.state;
    const options = { year: "numeric", month: "short", day: "numeric" };

    const columns = [
      {
        name: "creation_date",
        label: "buyer",
        options: {
          renderValue: (value, order) => (
            <div>
              <div className={classes.userName}>{order.user_name}</div>
              <div className={classes.date}>{`Purchased date: ${new Date(value).toLocaleDateString("en-US", options)}`}</div>
            </div>
          ),
        },
      },
      {
        name: "Total",
        label: "Total",
        options: {
          renderValue: (value, order) => (
            <div>
              {order.total}
              {order.currency}
            </div>
          ),
        },
      },
      {
        name: "order_id",
        label: " ",
        options: {
          renderValue: (value) => <Link to={`/orders/${value}/order-details`}><ArrowDown /></Link>,
        },
      },
    ];

    const optionsTable = {
      rowsPerPage: 3, // Default number of rows per page
      rowsPerPageOptions: [3, 15, 30, 50, 100], // Custom options
      filterType: "dropdown",
      responsive: "standard",
      sort: false,
      filter: false,
      search: false,
      viewColumns: false,
      download: false,
      print: false,
      count: total,
      rowsPerPage,
      page,
      serverSide: true,
      onChangePage: (pageIndex) => {
        this.setState({ page: pageIndex }, this.getOrders);
      },
      onChangeRowsPerPage: (numberOfRows) => {
        this.setState({ rowsPerPage: numberOfRows, page: 0 }, this.getOrders);
      },
      selectableRows: "none",
    };

    if (loadingOrders) {
      return (
        <PapperBlock
          title="Latest Orders"
          noWrapper
          headingStyle={classes.headingStyle}
          headingExtraContent={<DotsVertical />}
          className={classes.paperBlock}
        >
          <div className={classes.spinner}>
            <Spinner />
          </div>
        </PapperBlock>
      );
    }
    if (!Object.keys(orders).length || orders?.empty) {
      return (
        <PapperBlock
          title="Latest Orders"
          noWrapper
          headingStyle={classes.headingStyle}
          headingExtraContent={<DotsVertical />}
          className={classes.paperBlock}
        >
          <div className={classes.spinner}>
            <EmptyData
              height="100%"
              icon={<OrdersIcon />}
              msg="No latest orders"
              description="There are no latest orders yet, we will keep you updated."
            />
          </div>
        </PapperBlock>
      );
    }

    return (
      <>
        <PapperBlock
          title="Latest Orders"
          noWrapper
          headingStyle={classes.headingStyle}
          headingExtraContent={<DotsVertical />}
          className={classes.paperBlock}
        >
          <EnhancedDataTable
            data={orders || []}
            columns={columns}
            options={optionsTable}
            className={classes.table}
          />
        </PapperBlock>
      </>
    );
  }
}

LatestOrders.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LatestOrders);
