import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TagsTreeCard } from "components";
import styles from "./renderTree-jss";
const RenderTree = ({ classes, tagsTree }) => (
  <div className={classes.tree}>
    <ul>
      {tagsTree?.map((tag) => (
        <li>
          <TagsTreeCard tag={tag} key={tag?.id} />
        </li>
      ))}
    </ul>
  </div>
);

export default withStyles(styles)(RenderTree);
