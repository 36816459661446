/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { reducer as form } from "redux-form/immutable";
import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router/immutable";
import history from "utils/history";

import languageProviderReducer from "containers/LanguageProvider/reducer";
import login from "./modules/login";
import users from "./modules/users";
import uiReducer from "./modules/ui";
import initval from "./modules/initForm";
import forms from "./modules/forms";
import notifications from "./modules/notifications";
import confirmation from "./modules/confirmation";
import cachedData from "./modules/cachedData";

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    form,
    login,
    users,
    ui: uiReducer,
    initval,
    language: languageProviderReducer,
    router: connectRouter(history),
    forms,
    notifications,
    confirmation,
    cachedData,
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  const mergeWithRouterState = connectRouter(history);
  return mergeWithRouterState(rootReducer);
}
