import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import DashboardWrapper from "components/DashboardWrapper/DashboardWrapper";
import PropTypes from "prop-types";
import React from "react";
import { compose } from "recompose";
import styles from "./papperStyle-jss";

class PapperBlock extends React.Component {
  render() {
    const {
      classes,
      title,
      description,
      children,
      colorMode,
      headingExtraContent,
      headingBorder,
      hideHeading,
      className,
      icon,
      headingStyle,
      noWrapper,
    } = this.props;
    return (
      <>
        {noWrapper ? (
          <Paper
            className={classNames(
              classes.root,
              className,
              colorMode && classes.colorMode
            )}
            elevation={0}
          >
            <div
              className={`${classes.heading} ${
                headingBorder ? classes.headingBorder : ""
              } ${hideHeading ? classes.hideHeading : ""} ${
                headingStyle || ""
              }`}
            >
              <div
                className={`${classes.titleText} ${
                  icon ? classes.iconStyle : ""
                }`}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.title}
                >
                  {title}
                </Typography>
                {icon && (
                  <Tooltip title="Filter unread" placement="top">
                    <Button className={classes.iconButton}>{icon}</Button>
                  </Tooltip>
                )}
                {!!description && (
                  <div className={`${classes.description} ${headingBorder ? classes.descriptionMargin : ""}`}>{description}</div>
                )}
              </div>
              {!!headingExtraContent && <div className={classes.headingExtraContent}>{headingExtraContent}</div>}
            </div>
            {children}
          </Paper>
        ) : (
          <DashboardWrapper marginAll>
            <Paper
              className={classNames(
                classes.root,
                className,
                colorMode && classes.colorMode
              )}
              elevation={0}
            >
              <div
                className={`${classes.heading} ${
                  headingBorder ? classes.headingBorder : ""
                } ${hideHeading ? classes.hideHeading : ""} ${
                  headingStyle || ""
                }`}
              >
                <div
                  className={`${classes.titleText} ${
                    icon ? classes.iconStyle : ""
                  }`}
                >
                  <Typography
                    variant="h6"
                    component="h2"
                    className={classes.title}
                  >
                    {title}
                  </Typography>
                  {icon && (
                    <Tooltip title="Filter unread" placement="top">
                      <Button className={classes.iconButton}>{icon}</Button>
                    </Tooltip>
                  )}
                  {!!description && (
                    <div className={`${classes.description} ${headingBorder ? classes.descriptionMargin : ""}`}>{description}</div>
                  )}
                </div>
                {!!headingExtraContent && <div className={classes.headingExtraContent}>{headingExtraContent}</div>}
              </div>
              {children}
            </Paper>
          </DashboardWrapper>
        )}
      </>
    );
  }
}
PapperBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  colorMode: PropTypes.bool,
  headingExtraContent: PropTypes.node,
  headingBorder: PropTypes.bool,
  noWrapper: PropTypes.bool,
  icon: PropTypes.node,
};
PapperBlock.defaultProps = {
  colorMode: false,
  headingExtraContent: null,
  headingBorder: false,
  description: "",
  icon: null,
};
export default compose(withStyles(styles))(PapperBlock);
