const styles = (theme) => ({
  accordion: {
    width: "100%",
  },
  accordionTitle: {
    width: "100%",
    padding: 0,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.9,
    textTransform: "capitalize",
    paddingBottom: "0.25rem",
    background: "none",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 0,
    "& span": {
      transition: "transform .2s ease-in-out"
    }
  },
  bordered: {
    borderBottom: "1.5px solid #D9D9D9",
  },
  primary: {
    color: theme.palette.primary.main,
  },
  content: {
    maxHeight: 0,
    overflow: "hidden",
    marginTop: 10
  },
  open: {
    overflow: "auto !important",
    maxHeight: "300px !important",
  },
});

export default styles;
