import { Map, fromJS } from "immutable";
import { SAVE_CACHE, LOGOUT } from "../../actions/actionConstants";

const initialState = { cache: {} };

const initialImmutableState = fromJS(initialState);
export default function reducer(state = initialImmutableState, action = {}) {
  switch (action.type) {
    case SAVE_CACHE: {
      let { data } = action;
      switch (action.field) {
        case "orgData":
          data = { ...data, currency: data.currency || "EGP" };
          break;
        default:
      }
      return state
        .asMutable()
        .setIn(
          ["cache", action.field],
          fromJS({ value: data, lastUpdated: action.requestDate })
        )
        .asImmutable();
    }
    case LOGOUT:
      return state.set("cache", Map());
    default:
      return state;
  }
}
