import React from "react";

const EditIcon = (props) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.753 4.10022L5.54302 12.7902C5.23302 13.1202 4.93302 13.7702 4.87302 14.2202L4.50302 17.4602C4.37302 18.6302 5.21302 19.4302 6.37302 19.2302L9.59302 18.6802C10.043 18.6002 10.673 18.2702 10.983 17.9302L19.193 9.24022C20.613 7.74022 21.253 6.03022 19.043 3.94022C16.843 1.87022 15.173 2.60022 13.753 4.10022Z"
      stroke="#9414FF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3828 5.5498C12.8128 8.3098 15.0528 10.4198 17.8328 10.6998"
      stroke="#9414FF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.49316 22.5H21.4932"
      stroke="#9414FF"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
