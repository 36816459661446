const styles = (theme) => ({
  tree: {
    display: "flex",
    justifyContent: "center",
    "& > ul": {
      width: "100%",
      display: "flex",
      position: "relative",
      transition: "all 0.5s",
      overflowX: "auto",
      padding: "10px 0",
      "&::-webkit-scrollbar": {
        display: "none"
      },
      "& > li": {
        width: "20%",
        minWidth: "fit-content",
        float: "left",
        textAlign: "center",
        listStyleType: "none",
        position: "relative",
        padding: "50px 5px 0 5px",
        transition: "all 0.5s",
        "&:before , &:after": {
          content: "''",
          position: "absolute",
          top: 0,
          right: "50%",
          borderTop: "2px solid #E0EBFF",
          width: "50%",
          height: "50px",
        },
        "&:after": {
          right: "auto",
          left: "50%",
          borderLeft: "2px solid #E0EBFF",
        },
        "&:only-child::after, &:only-child::before": {
          display: "none",
        },
        "&:only-child": {
          paddingTop: 0,
        },
        "&:first-child::before, &:last-child::after": {
          border: "none",
        },
        "&:last-child:before": {
          borderRight: `3px solid ${theme.palette.primary.light}`,
          borderRadius: "0 55px 0 0",
        },
        "&:first-child::after": {
          borderRadius: "55px 0 0 0",
        },
      },
    },
  },
});

export default styles;
