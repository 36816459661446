const getPrices = stock => {
  const { price, discount } = stock;
  return {
    sale: Number(price - price * ((discount || 0) / 100)),
    original: price,
  };
};

/**
 * merge multiple filters objects with priority to the later
 * @param  {...object} filtersObjects each param is a filter object
 */
const mergeFilters = (...filtersObjects) => {
  let result = { tags: [] };
  filtersObjects.forEach(filter => {
    result = {
      ...result,
      ...filter,
      tags: [...result.tags, ...(filter?.tags || [])]
    };
  });
  return result;
};

export { getPrices, mergeFilters };
