const images = [
  // 1
  "http://via.placeholder.com/1050x700/2196F3/FFFFFF/",
  "http://via.placeholder.com/1050x700/3F51B5/FFFFFF/",
  "http://via.placeholder.com/1050x700/00BCD4/FFFFFF/",
  "http://via.placeholder.com/1050x700/009688/FFFFFF/",
  "http://via.placeholder.com/1050x700/01579B/FFFFFF/",
  "http://via.placeholder.com/1050x700/0097A7/FFFFFF/",
  "http://via.placeholder.com/1050x700/43A047/FFFFFF/",
  "http://via.placeholder.com/1050x700/558B2F/FFFFFF/",
  "http://via.placeholder.com/1050x700/1DE9B6/767676/",
  "http://via.placeholder.com/1050x700/00E5FF/767676/",
  "http://via.placeholder.com/1050x700/C6FF00/767676/",
  // 2
  "http://via.placeholder.com/1050x700/D4E157/767676/",
  "http://via.placeholder.com/1050x700/F8BBD0/767676/",
  "http://via.placeholder.com/1050x700/FFCA28/767676/",
  "http://via.placeholder.com/1050x700/CFD8DC/767676/",
  "http://via.placeholder.com/1050x700/673AB7/FFFFFF/",
  "http://via.placeholder.com/1050x700/EF5350/FFFFFF/",
  "http://via.placeholder.com/1050x700/1E88E5/FFFFFF/",
  "http://via.placeholder.com/1050x700/3D5AFE/FFFFFF/",
  "http://via.placeholder.com/1050x700/EF6C00/FFFFFF/",
  "http://via.placeholder.com/1050x700/795548/FFFFFF/",
  // 3
  "http://via.placeholder.com/1050x700/FFE57F/767676/",
  "http://via.placeholder.com/1050x700/DCEDC8/767676/",
  "http://via.placeholder.com/1050x700/E1BEE7/767676/",
  "http://via.placeholder.com/1050x700/BBDEFB/767676/",
  "http://via.placeholder.com/1050x700/388E3C/FFFFFF/",
  "http://via.placeholder.com/1050x700/651FFF/FFFFFF/",
  "http://via.placeholder.com/1050x700/757575/FFFFFF/",
  "http://via.placeholder.com/1050x700/E91E63/FFFFFF/",
  "http://via.placeholder.com/1050x700/607D8B/FFFFFF/",
  "http://via.placeholder.com/1050x700/AA00FF/FFFFFF/",
  // 4
  "http://via.placeholder.com/1050x700/827717/FFFFFF/",
  "http://via.placeholder.com/1050x700/E64A19/FFFFFF/",
  "http://via.placeholder.com/1050x700/C2185B/FFFFFF/",
  "http://via.placeholder.com/1050x700/AA00FF/FFFFFF/",
  "http://via.placeholder.com/1050x700/1976D2/FFFFFF/",
  "http://via.placeholder.com/1050x700/D1C4E9/767676/",
  "http://via.placeholder.com/1050x700/81D4FA/767676/",
  "http://via.placeholder.com/1050x700/E0F2F1/767676/",
  "http://via.placeholder.com/1050x700/E6EE9C/767676/",
  "http://via.placeholder.com/1050x700/FFEB3B/767676/",
  // 5
  "http://via.placeholder.com/1050x700/E040FB/FFFFFF/",
  "http://via.placeholder.com/1050x700/C62828/FFFFFF/",
  "http://via.placeholder.com/1050x700/AD1457/FFFFFF/",
  "http://via.placeholder.com/1050x700/673AB7/FFFFFF/",
  "http://via.placeholder.com/1050x700/651FFF/FFFFFF/",
  "http://via.placeholder.com/1050x700/00BFA5/FFFFFF/",
  "http://via.placeholder.com/1050x700/A5D6A7/767676/",
  "http://via.placeholder.com/1050x700/AED581/767676/",
  "http://via.placeholder.com/1050x700/FFB74D/767676/",
  "http://via.placeholder.com/1050x700/00BFA5/767676/",
  // 6
  "http://via.placeholder.com/100x100/C6FF00/FFFFFF/",
  "http://via.placeholder.com/100x100/F44336/FFFFFF/",
  "http://via.placeholder.com/100x100/673AB7/FFFFFF/",
  "http://via.placeholder.com/100x100/03A9F4/FFFFFF/",
  "http://via.placeholder.com/100x100/4CAF50/FFFFFF/",
  "http://via.placeholder.com/100x100/FF5722/FFFFFF/",
  "http://via.placeholder.com/100x100/607D8B/FFFFFF/",
  "http://via.placeholder.com/100x100/795548/FFFFFF/",
  "http://via.placeholder.com/100x100/8BC34A/FFFFFF/",
  "http://via.placeholder.com/100x100/00BCD4/FFFFFF/",
];

export default images;
