import React from "react";

const DeleteIcon = (props) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.493 6.47998C18.163 6.14998 14.813 5.97998 11.473 5.97998C9.49304 5.97998 7.51304 6.07998 5.53304 6.27998L3.49304 6.47998"
      stroke="#FF0000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99292 5.47L9.21292 4.16C9.37292 3.21 9.49292 2.5 11.1829 2.5H13.8029C15.4929 2.5 15.6229 3.25 15.7729 4.17L15.9929 5.47"
      stroke="#FF0000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.3429 9.64014L18.6929 19.7101C18.5829 21.2801 18.4929 22.5001 15.7029 22.5001H9.28294C6.49294 22.5001 6.40294 21.2801 6.29294 19.7101L5.64294 9.64014"
      stroke="#FF0000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8229 17H14.1529"
      stroke="#FF0000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99292 13H14.9929"
      stroke="#FF0000"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DeleteIcon;
