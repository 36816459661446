import React from "react";

const ArrowRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M5.94001 13.78C5.81335 13.78 5.68668 13.7333 5.58668 13.6333C5.39335 13.44 5.39335 13.12 5.58668 12.9267L9.93335 8.58001C10.2533 8.26001 10.2533 7.74001 9.93335 7.42001L5.58668 3.07335C5.39335 2.88001 5.39335 2.56001 5.58668 2.36668C5.78001 2.17335 6.10001 2.17335 6.29335 2.36668L10.64 6.71335C10.98 7.05335 11.1733 7.51335 11.1733 8.00001C11.1733 8.48668 10.9867 8.94668 10.64 9.28668L6.29335 13.6333C6.19335 13.7267 6.06668 13.78 5.94001 13.78Z"
      fill="white"
    />
  </svg>
);

export default ArrowRight;
