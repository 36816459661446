import React from "react";
import PropTypes from "prop-types";
import qs from "qs";
import Outer from "../../Templates/Outer";
import PasswordRecovery from "../Users/PasswordRecovery";

class PasswordRecoveryDedicated extends React.Component {
  render() {
    const { location, history } = this.props;
    const { token } = qs.parse(location.search, { ignoreQueryPrefix: true, depth: 0 });
    return (
      <Outer>
        <PasswordRecovery token={token} history={history} />
      </Outer>
    );
  }
}

PasswordRecoveryDedicated.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default PasswordRecoveryDedicated;
