import React from "react";

const EditStrokeIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.18264 1.94991L2.73556 6.657C2.56764 6.83575 2.40514 7.18783 2.37264 7.43158L2.17223 9.18658C2.10181 9.82033 2.55681 10.2537 3.18514 10.1453L4.92931 9.84742C5.17306 9.80408 5.51431 9.62533 5.68223 9.44117L10.1293 4.73408C10.8985 3.92158 11.2451 2.99533 10.0481 1.86325C8.85639 0.741997 7.95181 1.13741 7.18264 1.94991Z"
      stroke="white"
      strokeWidth="0.8125"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.44043 2.73535C6.67335 4.23035 7.88668 5.37327 9.39251 5.52493"
      stroke="white"
      strokeWidth="0.8125"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.625 11.917H11.375"
      stroke="white"
      strokeWidth="0.8125"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditStrokeIcon;
