import React from "react";

const ArrowLeft = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M15.0899 3.90396C15.2799 3.90396 15.4699 3.97395 15.6199 4.12395C15.9099 4.41395 15.9099 4.89396 15.6199 5.18396L9.0999 11.704C8.6199 12.184 8.6199 12.964 9.0999 13.444L15.6199 19.964C15.9099 20.254 15.9099 20.734 15.6199 21.024C15.3299 21.314 14.8499 21.314 14.5599 21.024L8.0399 14.504C7.5299 13.994 7.2399 13.304 7.2399 12.574C7.2399 11.844 7.5199 11.154 8.0399 10.644L14.5599 4.12395C14.7099 3.98395 14.8999 3.90396 15.0899 3.90396Z"
      fill="#FFFFFF"
    />
  </svg>
);

export default ArrowLeft;
