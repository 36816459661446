import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const styles = theme => ({
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    padding: 0,
  },
});

class Notification extends React.Component {
  render() {
    const { classes, message, open, autoHideDuration, onClose } = this.props;
    return (
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={message}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

Notification.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  message: PropTypes.any,
  open: PropTypes.bool,
  autoHideDuration: PropTypes.number
};

Notification.defaultProps = {
  open: false,
  autoHideDuration: null,
  onClose: () => {},
  message: null
};

export default withStyles(styles)(Notification);
