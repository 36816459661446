import React from "react";
import Outer from "../../Templates/Outer";
import ForgotPassword from "../Users/ForgotPassword";

class ForgotPasswordDedicated extends React.Component {
  render() {
    return (
      <Outer>
        <ForgotPassword />
      </Outer>
    );
  }
}

export default ForgotPasswordDedicated;
