import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ArrowDown from "api/icons/ArrowDown";
import classNames from "classnames";
import styles from "./buttonAdd-jss";

const ButtonAdd = ({ title, handleClick, classes, className }) => (
  <Button
    variant="contained"
    color="primary"
    endIcon={<ArrowDown className={classes.arrowDown} />}
    onClick={handleClick}
    className={classNames(className)}
  >
    {title}
  </Button>
);

export default withStyles(styles)(ButtonAdd);
