import React, { useState, useEffect, useRef } from "react";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Header, Sidebar } from "components";
import EllipseBlur from "components/EllipseBlur/index";
import Spinner from "components/Spinner/Spinner";
import { PropTypes } from "prop-types";
import { Fab } from "@material-ui/core/index";
import ArrowLeft from "api/icons/ArrowLeft";
import ArrowRight from "api/icons/ArrowRight";
import { pagesAllowed } from "utils/headerStylePages";
import styles from "../appStyles-jss";

const LeftSidebarLayout = (props) => {
  const {
    classes,
    children,
    toggleDrawer,
    sidebarOpen,
    loadTransition,
    mode,
    gradient,
    history,
    changeMode,
    place,
    handleOpenGuide,
    user,
    dataMenu
  } = props;

  const [pageLoaded, setPageLoaded] = useState(props.pageLoaded);
  const [activeHeader, setActiveHeader] = useState(false);
  const [checkPage, setCheckPage] = useState(false);
  const mainContent = useRef(null);
  const mobileView = window.innerWidth < 600;

  useEffect(() => {
    setPageLoaded(props.pageLoaded);
  }, [props.pageLoaded]);

  useEffect(() => {
    if (checkPage) {
      const handleScroll = () => {
        const scrollPosition = mainContent.current.scrollTop;
        if (scrollPosition >= (mobileView ? 25 : 95)) {
          setActiveHeader(true);
        } else {
          setActiveHeader(false);
        }
      };

      mainContent?.current?.addEventListener("scroll", handleScroll, { passive: true });

      return () => {
        mainContent?.current?.removeEventListener("scroll", handleScroll);
      };
    }
  }, [checkPage]);

  useEffect(() => {
    setCheckPage(false);
    checkHeaderStyle();
  }, [history?.location?.pathname]);

  const checkHeaderStyle = () => {
    pagesAllowed.forEach((pagePath) => {
      const isAllowed = history.location.pathname === pagePath;
      if (isAllowed) {
        setCheckPage(true);
      }
    });
  };

  return (
    <div className={classes.mainContentContainer}>
      <div
        className={`${classes.sideBarContainer} ${sidebarOpen ? classes.sideBarOpen : ""}`}
      >
        <Sidebar
          open={sidebarOpen}
          toggleDrawerOpen={toggleDrawer}
          loadTransition={loadTransition}
          dataMenu={dataMenu}
          leftSidebar
          user={user}
        />
        <Fab
          size="small"
          className={classNames(
            classes.menuButton,
            !sidebarOpen ? classes.menuButtonClosed : "",
            classes.hideMobile
          )}
          aria-label="Menu"
          onClick={toggleDrawer}
        >
          {sidebarOpen ? <ArrowLeft /> : <ArrowRight />}
        </Fab>
      </div>
      <main ref={mainContent} className={classNames(classes.content, !sidebarOpen ? classes.contentPaddingLeft : "")} id="mainContent">
        <div className={classNames(classes.headerContainer, activeHeader || !checkPage ? classes.headerActive : "")}>
          <Header
            toggleDrawerOpen={toggleDrawer}
            margin={sidebarOpen}
            gradient={gradient}
            position="left-sidebar"
            changeMode={changeMode}
            mode={mode}
            title={place}
            history={history}
            openGuide={handleOpenGuide}
            user={user}
            sidebarOpen={sidebarOpen}
            activeHeader={activeHeader}
            checkPage={checkPage}
          />
        </div>
        <section className={classNames(classes.mainWrap, classes.sidebarLayout)}>
          {!pageLoaded && (<div className={classes.spinnerLeftSide}><div><Spinner /></div></div>)}
          <Fade
            in={pageLoaded}
            mountOnEnter
            unmountOnExit
            {...(pageLoaded ? { timeout: 700 } : {})}
          >
            <div className={!pageLoaded ? classes.hideApp : ""}>
              {/* Application content will load here */}
              {children}
            </div>
          </Fade>
        </section>
      </main>
      <div className={classes.ellipseOne}>
        <EllipseBlur width="807px" height="807px" borderRadius="807px" opacity="0.7" background="#9E77F6" />
      </div>
      <div className={classes.ellipseTwo}>
        <EllipseBlur width="813px" height="813px" borderRadius="813px" opacity="0.7" background="#B0D2E5" />
      </div>
      <div className={classes.ellipseThree}>
        <EllipseBlur width="807px" height="807px" borderRadius="807px" opacity="0.9" background="#77D8F6" />
      </div>
      <div className={classes.ellipseFour}>
        <EllipseBlur width="50rem" height="50rem" borderRadius="50rem" opacity="0.9" background="#77D8F6" />
      </div>
    </div>
  );
};

LeftSidebarLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  loadTransition: PropTypes.func.isRequired,
  changeMode: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  pageLoaded: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  gradient: PropTypes.bool.isRequired,
  deco: PropTypes.bool.isRequired,
  bgPosition: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  titleException: PropTypes.array,
  handleOpenGuide: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  dataMenu: PropTypes.array.isRequired
};

LeftSidebarLayout.defaultProps = {
  titleException: [/^\/?$/]
};

export default withStyles(styles)(LeftSidebarLayout);
