import { Map } from "immutable";
import {
  ADD_CONFIRMATION,
  REMOVE_CONFIRMATION
} from "../../actions/actionConstants";

const initialState = {};

const initialImmutableState = Map(initialState);
export default function reducer(state = initialImmutableState, action = {}) {
  switch (action.type) {
    case ADD_CONFIRMATION:
      return state
        .setIn(["confirmation", "title"], action.title)
        .setIn(["confirmation", "body"], action.body)
        .setIn(["confirmation", "confirmCallback"], action.confirmCallback || (() => {}))
        .setIn(["confirmation", "cancelCallback"], action.cancelCallbck || (() => {}));
    case REMOVE_CONFIRMATION:
      return state.delete("confirmation");
    default:
      return state;
  }
}
