const styles = (theme) => ({
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "auto",
    overflow: "auto",
    width: "100%",
    flexShrink: 0,
    "&::-webkit-scrollbar": {
      width: 4,
      height: 4,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: "16px",
    },
  },
  selectContainer: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    flexShrink: 0,
  },
  selectLabel: {
    color: "#8B909A",
    fontWeight: "500",
    fontSize: "15px",
    flexShrink: 0,
  },
  dropdownIcon: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
  },
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  pagButton: {
    minWidth: "unset",
    padding: "unset",
    width: "28px",
    height: "28px",
    display: "grid",
    placeItems: "center",
    cursor: "pointer",
    transition: "all 0.3s",
    borderRadius: "4px",
    backgroundColor: "#F1F2F6",
    color: "#8B909A",
    "&:hover": {
      background: "#9414FF26",
    },
    "&:disabled": {
      cursor: "not-allowed",
      opacity: 1,
    },
  },
  activePagButton: {
    color: "#9414FF",
    fontSize: "13px",
    fontWeight: "500",
    background: "#9414FF26",
    pointerEvents: "none",
  },

  ul: {
    display: "flex",
    gap: "12px",
  },
});

export default styles;
